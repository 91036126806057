// THIS FILE IS AUTOMATICALLY GENERATED BY SPACETIMEDB. EDITS TO THIS FILE
// WILL NOT BE SAVED. MODIFY TABLES IN RUST INSTEAD.

// @ts-ignore
import { __SPACETIMEDB__, AlgebraicType, ProductType, BuiltinType, ProductTypeElement, DatabaseTable, AlgebraicValue, ReducerArgsAdapter, SumTypeVariant, Serializer, Identity, Address, ReducerEvent, Reducer, SpacetimeDBClient } from "@clockworklabs/spacetimedb-sdk";

export class SetConfigReducer extends Reducer
{
	public static reducerName: string = "SetConfig";
	public static call(_platform: string, _channel: string, _debug: boolean, _updateHz: number, _editorBorder: number, _authentication: boolean, _strictMode: boolean, _authKey: string) {
		this.getReducer().call(_platform, _channel, _debug, _updateHz, _editorBorder, _authentication, _strictMode, _authKey);
	}

	public call(_platform: string, _channel: string, _debug: boolean, _updateHz: number, _editorBorder: number, _authentication: boolean, _strictMode: boolean, _authKey: string) {
		const serializer = this.client.getSerializer();
		let _platformType = AlgebraicType.createPrimitiveType(BuiltinType.Type.String);
		serializer.write(_platformType, _platform);
		let _channelType = AlgebraicType.createPrimitiveType(BuiltinType.Type.String);
		serializer.write(_channelType, _channel);
		let _debugType = AlgebraicType.createPrimitiveType(BuiltinType.Type.Bool);
		serializer.write(_debugType, _debug);
		let _updateHzType = AlgebraicType.createPrimitiveType(BuiltinType.Type.U32);
		serializer.write(_updateHzType, _updateHz);
		let _editorBorderType = AlgebraicType.createPrimitiveType(BuiltinType.Type.U32);
		serializer.write(_editorBorderType, _editorBorder);
		let _authenticationType = AlgebraicType.createPrimitiveType(BuiltinType.Type.Bool);
		serializer.write(_authenticationType, _authentication);
		let _strictModeType = AlgebraicType.createPrimitiveType(BuiltinType.Type.Bool);
		serializer.write(_strictModeType, _strictMode);
		let _authKeyType = AlgebraicType.createPrimitiveType(BuiltinType.Type.String);
		serializer.write(_authKeyType, _authKey);
		this.client.call("SetConfig", serializer);
	}

	public static deserializeArgs(adapter: ReducerArgsAdapter): any[] {
		let platformType = AlgebraicType.createPrimitiveType(BuiltinType.Type.String);
		let platformValue = AlgebraicValue.deserialize(platformType, adapter.next())
		let platform = platformValue.asString();
		let channelType = AlgebraicType.createPrimitiveType(BuiltinType.Type.String);
		let channelValue = AlgebraicValue.deserialize(channelType, adapter.next())
		let channel = channelValue.asString();
		let debugType = AlgebraicType.createPrimitiveType(BuiltinType.Type.Bool);
		let debugValue = AlgebraicValue.deserialize(debugType, adapter.next())
		let debug = debugValue.asBoolean();
		let updateHzType = AlgebraicType.createPrimitiveType(BuiltinType.Type.U32);
		let updateHzValue = AlgebraicValue.deserialize(updateHzType, adapter.next())
		let updateHz = updateHzValue.asNumber();
		let editorBorderType = AlgebraicType.createPrimitiveType(BuiltinType.Type.U32);
		let editorBorderValue = AlgebraicValue.deserialize(editorBorderType, adapter.next())
		let editorBorder = editorBorderValue.asNumber();
		let authenticationType = AlgebraicType.createPrimitiveType(BuiltinType.Type.Bool);
		let authenticationValue = AlgebraicValue.deserialize(authenticationType, adapter.next())
		let authentication = authenticationValue.asBoolean();
		let strictModeType = AlgebraicType.createPrimitiveType(BuiltinType.Type.Bool);
		let strictModeValue = AlgebraicValue.deserialize(strictModeType, adapter.next())
		let strictMode = strictModeValue.asBoolean();
		let authKeyType = AlgebraicType.createPrimitiveType(BuiltinType.Type.String);
		let authKeyValue = AlgebraicValue.deserialize(authKeyType, adapter.next())
		let authKey = authKeyValue.asString();
		return [platform, channel, debug, updateHz, editorBorder, authentication, strictMode, authKey];
	}

	public static on(callback: (reducerEvent: ReducerEvent, _platform: string, _channel: string, _debug: boolean, _updateHz: number, _editorBorder: number, _authentication: boolean, _strictMode: boolean, _authKey: string) => void) {
		this.getReducer().on(callback);
	}
	public on(callback: (reducerEvent: ReducerEvent, _platform: string, _channel: string, _debug: boolean, _updateHz: number, _editorBorder: number, _authentication: boolean, _strictMode: boolean, _authKey: string) => void)
	{
		this.client.on("reducer:SetConfig", callback);
	}
}


export default SetConfigReducer
