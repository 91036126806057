// THIS FILE IS AUTOMATICALLY GENERATED BY SPACETIMEDB. EDITS TO THIS FILE
// WILL NOT BE SAVED. MODIFY TABLES IN RUST INSTEAD.

// @ts-ignore
import { __SPACETIMEDB__, AlgebraicType, SumTypeVariant, BuiltinType, AlgebraicValue } from "@clockworklabs/spacetimedb-sdk";

export namespace ImageElementData {
	export function getAlgebraicType(): AlgebraicType {
		return AlgebraicType.createSumType([
			new SumTypeVariant("ElementDataId", AlgebraicType.createPrimitiveType(BuiltinType.Type.U32)),
			new SumTypeVariant("RawData", AlgebraicType.createPrimitiveType(BuiltinType.Type.String)),
		]);
	}

	export function serialize(value: ImageElementData): object {
		switch(value.tag) {
			case "ElementDataId":
				return { "ElementDataId": value.value };
			case "RawData":
				return { "RawData": value.value };
			default:
				throw("unreachable");
		}
	}

	export type ElementDataId = { tag: "ElementDataId", value: number };
	export const ElementDataId = (value: number): ElementDataId => ({ tag: "ElementDataId", value });
	export type RawData = { tag: "RawData", value: string };
	export const RawData = (value: string): RawData => ({ tag: "RawData", value });

	export function fromValue(value: AlgebraicValue): ImageElementData {
		let sumValue = value.asSumValue();
		switch(sumValue.tag) {
			case 0:
				return { tag: "ElementDataId", value: sumValue.value.asNumber() };
			case 1:
				return { tag: "RawData", value: sumValue.value.asString() };
			default:
				throw("unreachable");
		}
	}
}

export type ImageElementData = ImageElementData.ElementDataId | ImageElementData.RawData;
export default ImageElementData;
