// THIS FILE IS AUTOMATICALLY GENERATED BY SPACETIMEDB. EDITS TO THIS FILE
// WILL NOT BE SAVED. MODIFY TABLES IN RUST INSTEAD.

// @ts-ignore
import { __SPACETIMEDB__, AlgebraicType, ProductType, BuiltinType, ProductTypeElement, DatabaseTable, AlgebraicValue, ReducerArgsAdapter, SumTypeVariant, Serializer, Identity, Address, ReducerEvent, Reducer, SpacetimeDBClient } from "@clockworklabs/spacetimedb-sdk";

export class UpdateElementDataNameReducer extends Reducer
{
	public static reducerName: string = "UpdateElementDataName";
	public static call(_dataId: number, _name: string) {
		this.getReducer().call(_dataId, _name);
	}

	public call(_dataId: number, _name: string) {
		const serializer = this.client.getSerializer();
		let _dataIdType = AlgebraicType.createPrimitiveType(BuiltinType.Type.U32);
		serializer.write(_dataIdType, _dataId);
		let _nameType = AlgebraicType.createPrimitiveType(BuiltinType.Type.String);
		serializer.write(_nameType, _name);
		this.client.call("UpdateElementDataName", serializer);
	}

	public static deserializeArgs(adapter: ReducerArgsAdapter): any[] {
		let dataIdType = AlgebraicType.createPrimitiveType(BuiltinType.Type.U32);
		let dataIdValue = AlgebraicValue.deserialize(dataIdType, adapter.next())
		let dataId = dataIdValue.asNumber();
		let nameType = AlgebraicType.createPrimitiveType(BuiltinType.Type.String);
		let nameValue = AlgebraicValue.deserialize(nameType, adapter.next())
		let name = nameValue.asString();
		return [dataId, name];
	}

	public static on(callback: (reducerEvent: ReducerEvent, _dataId: number, _name: string) => void) {
		this.getReducer().on(callback);
	}
	public on(callback: (reducerEvent: ReducerEvent, _dataId: number, _name: string) => void)
	{
		this.client.on("reducer:UpdateElementDataName", callback);
	}
}


export default UpdateElementDataNameReducer
