// THIS FILE IS AUTOMATICALLY GENERATED BY SPACETIMEDB. EDITS TO THIS FILE
// WILL NOT BE SAVED. MODIFY TABLES IN RUST INSTEAD.

// @ts-ignore
import { __SPACETIMEDB__, AlgebraicType, ProductType, BuiltinType, ProductTypeElement, DatabaseTable, AlgebraicValue, ReducerArgsAdapter, SumTypeVariant, Serializer, Identity, Address, ReducerEvent, Reducer, SpacetimeDBClient } from "@clockworklabs/spacetimedb-sdk";

export class UpdateElementDataDataReducer extends Reducer
{
	public static reducerName: string = "UpdateElementDataData";
	public static call(_dataId: number, _data: string) {
		this.getReducer().call(_dataId, _data);
	}

	public call(_dataId: number, _data: string) {
		const serializer = this.client.getSerializer();
		let _dataIdType = AlgebraicType.createPrimitiveType(BuiltinType.Type.U32);
		serializer.write(_dataIdType, _dataId);
		let _dataType = AlgebraicType.createPrimitiveType(BuiltinType.Type.String);
		serializer.write(_dataType, _data);
		this.client.call("UpdateElementDataData", serializer);
	}

	public static deserializeArgs(adapter: ReducerArgsAdapter): any[] {
		let dataIdType = AlgebraicType.createPrimitiveType(BuiltinType.Type.U32);
		let dataIdValue = AlgebraicValue.deserialize(dataIdType, adapter.next())
		let dataId = dataIdValue.asNumber();
		let dataType = AlgebraicType.createPrimitiveType(BuiltinType.Type.String);
		let dataValue = AlgebraicValue.deserialize(dataType, adapter.next())
		let data = dataValue.asString();
		return [dataId, data];
	}

	public static on(callback: (reducerEvent: ReducerEvent, _dataId: number, _data: string) => void) {
		this.getReducer().on(callback);
	}
	public on(callback: (reducerEvent: ReducerEvent, _dataId: number, _data: string) => void)
	{
		this.client.on("reducer:UpdateElementDataData", callback);
	}
}


export default UpdateElementDataDataReducer
