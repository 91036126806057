// THIS FILE IS AUTOMATICALLY GENERATED BY SPACETIMEDB. EDITS TO THIS FILE
// WILL NOT BE SAVED. MODIFY TABLES IN RUST INSTEAD.

// @ts-ignore
import { __SPACETIMEDB__, AlgebraicType, ProductType, BuiltinType, ProductTypeElement, DatabaseTable, AlgebraicValue, ReducerArgsAdapter, SumTypeVariant, Serializer, Identity, Address, ReducerEvent, Reducer, SpacetimeDBClient } from "@clockworklabs/spacetimedb-sdk";
// @ts-ignore
import { ElementStruct } from "./element_struct";

export class UpdateElementReducer extends Reducer
{
	public static reducerName: string = "UpdateElement";
	public static call(_elementId: number, _element: ElementStruct, _transparency: number, _transform: string, _clip: string, _locked: boolean) {
		this.getReducer().call(_elementId, _element, _transparency, _transform, _clip, _locked);
	}

	public call(_elementId: number, _element: ElementStruct, _transparency: number, _transform: string, _clip: string, _locked: boolean) {
		const serializer = this.client.getSerializer();
		let _elementIdType = AlgebraicType.createPrimitiveType(BuiltinType.Type.U32);
		serializer.write(_elementIdType, _elementId);
		let _elementType = ElementStruct.getAlgebraicType();
		serializer.write(_elementType, _element);
		let _transparencyType = AlgebraicType.createPrimitiveType(BuiltinType.Type.I32);
		serializer.write(_transparencyType, _transparency);
		let _transformType = AlgebraicType.createPrimitiveType(BuiltinType.Type.String);
		serializer.write(_transformType, _transform);
		let _clipType = AlgebraicType.createPrimitiveType(BuiltinType.Type.String);
		serializer.write(_clipType, _clip);
		let _lockedType = AlgebraicType.createPrimitiveType(BuiltinType.Type.Bool);
		serializer.write(_lockedType, _locked);
		this.client.call("UpdateElement", serializer);
	}

	public static deserializeArgs(adapter: ReducerArgsAdapter): any[] {
		let elementIdType = AlgebraicType.createPrimitiveType(BuiltinType.Type.U32);
		let elementIdValue = AlgebraicValue.deserialize(elementIdType, adapter.next())
		let elementId = elementIdValue.asNumber();
		let elementType = ElementStruct.getAlgebraicType();
		let elementValue = AlgebraicValue.deserialize(elementType, adapter.next())
		let element = ElementStruct.fromValue(elementValue);
		let transparencyType = AlgebraicType.createPrimitiveType(BuiltinType.Type.I32);
		let transparencyValue = AlgebraicValue.deserialize(transparencyType, adapter.next())
		let transparency = transparencyValue.asNumber();
		let transformType = AlgebraicType.createPrimitiveType(BuiltinType.Type.String);
		let transformValue = AlgebraicValue.deserialize(transformType, adapter.next())
		let transform = transformValue.asString();
		let clipType = AlgebraicType.createPrimitiveType(BuiltinType.Type.String);
		let clipValue = AlgebraicValue.deserialize(clipType, adapter.next())
		let clip = clipValue.asString();
		let lockedType = AlgebraicType.createPrimitiveType(BuiltinType.Type.Bool);
		let lockedValue = AlgebraicValue.deserialize(lockedType, adapter.next())
		let locked = lockedValue.asBoolean();
		return [elementId, element, transparency, transform, clip, locked];
	}

	public static on(callback: (reducerEvent: ReducerEvent, _elementId: number, _element: ElementStruct, _transparency: number, _transform: string, _clip: string, _locked: boolean) => void) {
		this.getReducer().on(callback);
	}
	public on(callback: (reducerEvent: ReducerEvent, _elementId: number, _element: ElementStruct, _transparency: number, _transform: string, _clip: string, _locked: boolean) => void)
	{
		this.client.on("reducer:UpdateElement", callback);
	}
}


export default UpdateElementReducer
